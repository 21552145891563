<template>
  <div>
    <CInput
      v-model="slug"
      :add-input-classes="invalidSlug ? 'input-error' : ''"
      :add-label-classes="invalidSlug ? 'label-error' : ''"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      @input="handleInput"
      @blur="checkUrl"
    />
    <span v-if="invalidSlug" class="error-message"
      >URL já cadastrada. Sugestão: <b>{{ slugSuggested }}</b></span
    >
  </div>
</template>

<script>
import slugify from 'slugify'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: 'URL Amigável*'
    },

    placeholder: {
      type: String,
      default: 'URL Amigável'
    },

    slugSuggested: {
      type: String,
      default: ''
    },

    required: {
      type: Boolean,
      default: true
    },

    invalidSlug: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      slug: '',
      edited: false
    }
  },

  watch: {
    value: {
      handler() {
        this.slugify()
      }
    },
    slug: {
      handler() {
        this.slugify()
      }
    }
  },

  methods: {
    slugify() {
      const value = this.edited ? this.slug : this.value
      this.slug = slugify(value, {
        replacement: '-',
        strict: true,
        remove: /[^a-zA-Z\d\s:-]/,
        lower: true
      })
      this.$emit('change', this.slug)
    },

    handleInput() {
      this.$set(this, 'edited', true)
      this.slugify()
    },

    checkUrl() {
      this.$emit('checkUrl')
    }
  }
}
</script>

<style lang="scss">
.error-message {
  display: block;
  margin-top: -10px;
  margin-bottom: 10px;
  color: #f02929;
}

.input-error {
  border: 1px solid #f02929;
}

.label-error {
  color: #f02929;
}
</style>
